import React, { Component } from "react";
import { Row, Skeleton, Alert, Card, Pagination, Table, Icon } from "antd";
import axios from "axios";
import moment from "moment";
import { ReactComponent as RightCheck } from "../../../assets/images/icons/right_check.svg";
import { ReactComponent as WrongCheck } from "../../../assets/images/icons/wrong_check.svg";
import { ReactComponent as warning_new } from "../../../assets/images/icons/warning_new.svg";
import { ReactComponent as download_new } from "../../../assets/images/icons/download_new.svg";
import NotFoundCard from "../../../Components/Dashboard/Common/NotFoundCard";

import "./index.scss";

import { connect } from "react-redux";
import qs from "qs";
import DownloadUtilityNav from "./DownloadUtilityNav";

class DownloadUtility extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    downloadList: [],
    jobsList: [],
    total: 0,
    totalJobs: 0,
    tabcount: 0,
    jobtabcount: 0,
    paging: {
      page: 1,
      pageSize: 25,
    },
    pagingJobs: {
      page: 1,
      pageSize: 25,
    },
    filtersjobs: {
      startDate: "",
      endDate: "",
      search: "",
    },
    filters: {
      startDate: "",
      endDate: "",
      search: "",
    },
    viewPage: "reporting",

    loadingJobs: false,
    errorJobs: false,
    errorMsgJobs: "",
    JobsList: [],
    jobs_count: 0,
    totalJobs: 0,
    tabcountJobs: 0,
    pagingJobs: {
      page: 1,
      pageSize: 25,
    },
    filtersJobs: {
      startDate: "",
      endDate: "",
      search: "",
    },
  };

  componentDidMount() {
    const params = qs.parse(window.location.search.replace(/\?/, ""));
    if (params.search) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: params.search,
            status: "all",
          },
        },
        () => this.loadData()
      );
    } else {
      this.loadData();
    }
  }

  componentDidUpdate(pp) {}

  // this  function used for load the all dispute store data, all data show in disputes state
  loadData() {
    console.log(this.props.count);
    if (window.location.hash) {
      if (window.location.hash === "#2") {
        this.setState({
          viewPage: "jobs",
        });
        this.jobsLoad();
      }
    }

    if (window.location.search === "?jobs") {
      this.setState({
        viewPage: "jobs",
      });
      this.jobsLoad();
    }

    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/store/downloads_list",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.filters,
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                downloadList: res.data.data,
                total: res.data.count,
                tabcount: res.data.count,
                loading: false,
                error: false,
                errorMsg: "",
                jobs_count: res.data.jobs_count,
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
                loading: false,
              });
            }
          })
          .catch((e) => {
            this.setState({
              error: true,
              errorMsg: e.message,
              loading: false,
            });
          });
      }
    );
  }
  jobsLoad() {
    this.setState(
      {
        loadingJobs: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/v2/orders/order_jobs_listing",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.filtersjobs,
            page: this.state.pagingJobs.page,
            pageSize: this.state.pagingJobs.pageSize,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                jobsList: res.data.data,
                totalJobs: res.data.count,
                jobtabcount: res.data.count,
                loadingJobs: false,
                errorJobs: false,
                errorMsgJobs: "",
              });
            } else {
              this.setState({
                errorJobs: true,
                errorMsgJobss: res.data.msg,
                loadingJobs: false,
              });
            }
          })
          .catch((e) => {
            this.setState({
              errorJobs: true,
              errorJobs: e.message,
              loadingJobs: false,
            });
          });
      }
    );
  }
  // this function used for filter data in global search
  changeFilter(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        paging: { ...this.state.paging, page: 1 },
      },

      () => this.loadData()
    );
  }

  //this function used for go  particular page in pagination
  goToPage(page) {
    this.setState(
      {
        paging: { ...this.state.paging, page },
      },
      () => {
        this.loadData();
      }
    );
  }

  // this function used for  show size of pagination
  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        paging: { ...this.state.paging, pageSize: pageSize },
      },
      () => this.loadData()
    );
  }
  //this function used for go  particular page in pagination for JOBS
  goToPageJobs(page) {
    this.setState(
      {
        pagingJobs: { ...this.state.pagingJobs, page },
      },
      () => {
        this.jobsLoad();
      }
    );
  }

  // this function used for  show size of pagination forJOBS
  onShowSizeChangeJobs(current, pageSize) {
    this.setState(
      {
        pagingJobs: { ...this.state.pagingJobs, pageSize: pageSize },
      },
      () => this.jobsLoad()
    );
  }
  onViewPageChange(page) {
    console.log("in ", page);
    this.setState({
      viewPage: page,
    });
  }
  render() {
    console.log(this.state);
    const dataSource = this.state.downloadList;

    const columns = [
      // {
      //   title: "Report ID",
      //   dataIndex: "jbid",
      //   key: "jbid",
      //   width: 134,
      // },
      {
        title: "Report Request Date",
        dataIndex: "created_date",
        key: "created_date",
        width: 180,
        render: (created_date) => {
          return !!created_date.length
            ? moment(created_date).format("DD MMM, YYYY  |  HH:mm")
            : "N/A";
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 126,
        render: (status) => {
          if (status.toLowerCase() === "pending") {
            return (
              <div className="status">
                <Icon component={warning_new} />
                <span>Pending</span>
              </div>
            );
          }

          if (status.toLowerCase() === "expired") {
            return (
              <div className="status">
                <Icon component={WrongCheck} />
                <span>Expired</span>
              </div>
            );
          }
          if (status.toLowerCase() === "processed") {
            return (
              <div className="status">
                {" "}
                <Icon component={RightCheck} />
                <span>Success</span>
              </div>
            );
          }
        },
      },
      {
        title: "Report Names",
        dataIndex: "type",
        key: "type  ",
        width: 170,
      },
      {
        title: "Store Name",
        dataIndex: "store_name",
        key: "store_name",
        width: 190,
        render: (store_name) => {
          return !!store_name && !!store_name.length ? store_name : "N/A";
        },
      },
      {
        title: "Requested By",
        dataIndex: "created_by",
        key: "created_by",
        width: 160,
        render: (created_by) => {
          return !!created_by.length ? created_by : "N/A";
        },
      },
      {
        title: "Download Link",
        dataIndex: "file",
        key: "file",
        width: 170,
        render: (file, arr) => {
          return !!file.length ? (
            <a
              className="download-link"
              target="_blank"
              rel="noopener noreferrer"
              download={arr.type}
              href={file}
            >
              {" "}
              <Icon component={download_new} /> Download Report{" "}
            </a>
          ) : (
            <a className="download-link-disabled">
              {" "}
              <Icon component={download_new} /> Download Report{" "}
            </a>
          );
        },
      },
    ];
    const columnsJobs = [
      // {
      //   title: "Report ID",
      //   dataIndex: "jbid",
      //   key: "jbid",
      //   width: 134,
      // },
      {
        title: "Created At",
        dataIndex: "created_date",
        key: "created_date",
        width: 180,
        render: (created_date) => {
          return !!created_date.length
            ? moment(created_date).format("DD MMM, YYYY  |  HH:mm")
            : "N/A";
        },
      },
      {
        title: "Created By",
        dataIndex: "created_by",
        key: "created_by",
        width: 160,
        render: (created_by) => {
          return !!created_by.length ? created_by : "N/A";
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 126,
        render: (status) => {
          if (status.toLowerCase() === "pending") {
            return (
              <div className="status">
                <Icon component={warning_new} />
                <span>Pending</span>
              </div>
            );
          }

          if (status.toLowerCase() === "expired") {
            return (
              <div className="status">
                <Icon component={WrongCheck} />
                <span>Expired</span>
              </div>
            );
          }
          if (status.toLowerCase() === "processed") {
            return (
              <div className="status">
                {" "}
                <Icon component={RightCheck} />
                <span>Success</span>
              </div>
            );
          }
        },
      },
      {
        title: "Processed At",
        dataIndex: "processed_at",
        key: "processed_at",
        width: 180,
        render: (processed_at) => {
          return !!processed_at.length
            ? moment(processed_at).format("DD MMM, YYYY  |  HH:mm")
            : "N/A";
        },
      },
      // {
      //   title: "Report Names",
      //   dataIndex: "type",
      //   key: "type  ",
      //   width: 170,
      // },
      // {
      //   title: "Store Name",
      //   dataIndex: "store_name",
      //   key: "store_name",
      //   width: 190,
      //   render: (store_name) => {
      //     return !!store_name && !!store_name.length ? store_name : "N/A";
      //   },
      // },

      {
        title: "Logs",
        dataIndex: "file",
        key: "file",
        width: 170,
        render: (file, arr) => {
          return !!file.length ? (
            <a
              className="download-link"
              target="_blank"
              rel="noopener noreferrer"
              download={arr.type}
              href={file}
            >
              {" "}
              <Icon component={download_new} /> Download Logs{" "}
            </a>
          ) : (
            <a className="download-link-disabled">
              {" "}
              <Icon component={download_new} /> Download Logs{" "}
            </a>
          );
        },
      },
    ];

    const PageWrapper = (children) => {
      //console.log("ji",this.props.count);
      return (
        <div className="ndr-escalations">
          <DownloadUtilityNav
            filters={this.state.filters}
            changeHandler={(e) => this.changeHandler(e)}
            changeFilter={(e) => this.changeFilter(e)}
            store={this.props.store}
            tabcounts={this.state.tabcount}
            loading={this.state.loading}
            viewPage={this.state.viewPage}
            onViewPageChange={(page) => this.onViewPageChange(page)}
            tabcountJobs={this.state.jobtabcount || this.state.jobs_count}
            jobsLoad={() => this.jobsLoad()}
          />

          {children}
        </div>
      );
    };
    if (this.state.viewPage === "Jobs" && this.state.loadingJobs) {
      return PageWrapper(
        <Row className="skeleton">
          <Skeleton active />
        </Row>
      );
    }

    if (this.state.loadingJobs) {
      return PageWrapper(
        <Row className="skeleton">
          <Skeleton active />
        </Row>
      );
    }

    if (
      this.state.viewPage === "Jobs" &&
      !this.state.loadingJobs &&
      this.state.errorJobs
    ) {
      return PageWrapper(
        <Row style={{ marginBottom: "30px" }}>
          <Alert
            message="There was an error"
            description={this.state.errorMsg}
            type="error"
          />
        </Row>
      );
    }
    if (this.state.viewPage === "jobs") {
      return PageWrapper(
        <div>
          {this.state.jobsList.length > 0 ? (
            <>
              <div id="test">
                <div className="msg"></div>
                <Table
                  className="download-utility-table"
                  pagination={false}
                  dataSource={this.state.jobsList}
                  columns={columnsJobs}
                />
              </div>
              <Row>
                <Pagination
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  showQuickJumper
                  showSizeChanger
                  pageSizeOptions={["10", "25", "50"]}
                  onShowSizeChange={(p, d) => this.onShowSizeChangeJobs(p, d)}
                  current={this.state.pagingJobs.page}
                  onChange={(page) => this.goToPageJobs(page)}
                  total={this.state.totalJobs}
                  defaultPageSize={this.state.pagingJobs.pageSize}
                />
              </Row>
            </>
          ) : (
            <NotFoundCard
              mainHeading={"Oops! No Data Found."}
              secondHeading={
                <p>Looks like there are no downloads in this page.</p>
              }
            />
          )}
        </div>
      );
    }
    if (this.state.loading) {
      return PageWrapper(
        <Row className="skeleton">
          <Skeleton active />
        </Row>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <Row style={{ marginBottom: "30px" }}>
          <Alert
            message="There was an error"
            description={this.state.errorMsg}
            type="error"
          />
        </Row>
      );
    }

    return PageWrapper(
      <div>
        {this.state.downloadList.length > 0 ? (
          <>
            <div id="test">
              <div className="msg">
                *Please note that reports are flushed in 24 hours from the time
                of their completion.
              </div>
              <Table
                className="download-utility-table"
                pagination={false}
                dataSource={dataSource}
                columns={columns}
              />
            </div>
            <Row>
              <Pagination
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                showQuickJumper
                showSizeChanger
                pageSizeOptions={["10", "25", "50"]}
                onShowSizeChange={(p, d) => this.onShowSizeChange(p, d)}
                current={this.state.paging.page}
                onChange={(page) => this.goToPage(page)}
                total={this.state.total}
                defaultPageSize={this.state.paging.pageSize}
              />
            </Row>
          </>
        ) : (
          <NotFoundCard
            mainHeading={"Oops! No Data Found."}
            secondHeading={
              <p>Looks like there are no downloads in this page.</p>
            }
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  store: state.auth.auth.store,
  count: state.auth.count,
});
export default connect(mapStateToProps)(DownloadUtility);
