import {
  Row,
  Pagination,
  Card,
  Skeleton,
  Button,
  Table,
  Modal,
  Dropdown,
  Icon,
  Menu,
  Tooltip,
} from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
// import PrivateProductCard from "./PrivateProductCard";

import "./index.scss";
import RfqNav from "./RfqNav";
import qs from "qs";
import { ReactComponent as BlankIllustration } from "../../../assets/images/icons/BlankIllustration.svg";
import { ReactComponent as Imageplaceholder } from "../../../assets/images/icons/image-placeholder-new.svg";
import { ReactComponent as RightIcon } from "../../../assets/images/icons/right_check.svg";
import { ReactComponent as RedCross } from "../../../assets/images/icons/circle-cross.svg";
import { ReactComponent as EyeIcon } from "../../../assets/images/icons/eyeIcon.svg";
import { ReactComponent as ReopenIcon } from "../../../assets/images/icons/redo.svg";
import NotFoundCard from "../Common/NotFoundCard";
import RaiseRFQModal from "./RaiseRFQModal";
import QuoteModal from "./QuoteModal";
import moment from "moment";
import { Link } from "react-router-dom";
import ErrorCard from "../Common/ErrorCard";
import VariantModal from "./VariantModal";
import { CancelModal } from "./CancelModal";
import { ReopenModal } from "./ReopenModal ";
import { ViewReasonModal } from "./ViewReasonModal/index.js";
import GlobalTooltipIcon from "../Common/GlobalTooltipIcon";
class Rfq extends Component {
  state = {
    urlSearched: false,
    loading: true,
    error: false,
    errorCode: null,
    errorMsg: "",
    variantModal: {
      visible: false,
      variantData: [],
      name: "",
      qty: 0,
      type: "",
    },
    cancelModal: {
      visible: false,
      id: "",
      index: null,
    },
    reopenModal: {
      visible: false,
      id: "",
      index: null,
    },
    viewRejectReason: {
      visible: false,
      reason: "",
    },
    rfqData: [],
    rfqLeft: 0,
    rfqAvailability: 0,
    count: {},
    pagination: {
      current: 1,
      total: 0,
      pageSize: 10,
    },

    filters: {
      search: "",
      type: "open",
      startDate: "",
      endDate: "",
      status: "",
    },

    moq: {},
    currentRow: {},
    request_vfid: null,

    showRfqModal: false,

    showQuoteMOdal: false,

    showMediaModal: false,
    showMediaModalUrl: "",
  };

  componentDidMount() {
    this.loadData(true);
  }

  activeTabHandler(activeTab) {
    this.setState({
      filters: {
        ...this.state.filters,
        type: activeTab,
      },
    });
  }

  changeFilter(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        pagination: { ...this.state.pagination, current: 1 },
      },

      () => this.loadData()
    );
  }

  loadData(isMount = false) {
    this.setState({ loading: true }, () => {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = axios.CancelToken.source();
      axios({
        url: process.env.REACT_APP_API_URL + "/rfqrequests/get_rfq_requests",
        method: "post",
        cancelToken: this.cancelToken.token,
        data: {
          filters: { ...this.state.filters },
          page: this.state.pagination.current,
          pageSize: this.state.pagination.pageSize,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            if (
              isMount &&
              res.data.count.openCount < 1 &&
              res.data.count.completedCount > 0
            ) {
              this.changeFilter({ type: "completed", status: "" });
            } else {
              this.setState({
                rfqData: res.data.data,
                loading: false,
                count: res.data.count,
                error: false,
                rfqLeft: res.data.rfq_requests_status.available,
                rfqAvailability: res.data.rfq_requests_status.quota,
                pagination: {
                  ...this.state.pagination,
                  total: res.data.count.total,
                },
              });
            }
          } else {
            this.setState({
              error: true,
              errorMsg: res.data.msg,
              loading: false,
            });
          }
        })
        .catch((e) => {
          if (!!e.request) {
            this.setState({
              error: true,
              errorCode: e.request.status,
              errorMsg: e.request.statusText,
              loading: false,
            });
          } else {
            this.setState({
              error: false,
              errorMsg: e.message,
              loading: true,
            });
          }
        });
    });
  }

  changeHandler(kvpairs) {
    this.setState({
      filters: { ...this.state.filters, ...kvpairs },
    });
  }
  handleVariantDetailClose() {
    this.setState({
      variantModal: {
        visable: false,
        variantData: [],
        name: "",
        qty: 0,
      },
    });
  }

  handleTableChange = (pagination, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState(
      {
        pagination: pager,
      },
      () => this.loadData()
    );
  };

  imgHandler(fileUrl) {
    // Get the file extension.
    const fileExtension = fileUrl.split(".")[fileUrl.split(".").length - 1];

    // Check the file extension against a list of supported file types.
    const supportedFileTypes = ["xlsx", "png", "jpg", "jpeg", "pdf", "mp4"];
    if (supportedFileTypes.includes(fileExtension)) {
      // The file type is supported, so return the HTML for displaying the file.
      switch (fileExtension) {
        case "xlsx":
          return (
            <iframe width="100%" height="500px" title="file" src={fileUrl} />
          );
        case "png":
        case "jpg":
        case "jpeg":
          return <img width="100%" height="500px" alt="img" src={fileUrl} />;
        case "pdf":
          return (
            <embed
              src={fileUrl}
              type="application/pdf"
              width="100%"
              height="500px"
            />
          );
        case "mp4":
          return <video src={fileUrl} controls width="100%" height="500px" />;

        default:
          return null;
      }
    } else {
      // The file type is not supported, so return an empty string.
      return "No Media";
    }
  }

  render() {
    const status = {
      open: "Open",
      in_processing: "Processing",
      completed: "Completed",
      closed: "Completed",
      rejected: "Rejected",
      expired: "Expired",
      cancelled: "Cancelled",
      reopened: "Re-Opened",
      compliance: "In-Compliance",
    };

    const statusColor = {
      open: "#1370fc",
      reopened: "#a011d2",
      in_processing: "#BA772A",
      completed: "#22AC2D",
      cancelled: "#be763d",
      closed: "#22AC2D",
      rejected: "#fd104d",
      expired: "#fd104d",
      compliance: "blue",
    };

    const statusFontWeight = {
      open: "500",
      in_processing: "500",
      completed: "600",
      closed: "600",
      rejected: "500",
      expired: "500",
      cancelled: "500",
      reopened: "500",
      compliance: "500",
    };

    const columns = [
      {
        title: "Request",
        dataIndex: "createdon",
        align: "left",

        width: 200,
        render: (e, obj) => (
          <div>
            <div>
              {" "}
              <span style={{ fontWeight: 400, color: "#898989" }}>
                Requested on :{" "}
              </span>{" "}
              {e && moment(e).format("DD MMM, YYYY")}
            </div>
            <div>
              <span style={{ fontWeight: 400, color: "#898989" }}>
                Request ID:{" "}
              </span>
              {obj.request_vfid}
            </div>
          </div>
        ),
      },
      {
        width: 180,
        title: "Status",
        dataIndex: "status",
        align: "center",
        render: (data, arr) => {
          return (
            <p
              style={{
                color: statusColor[data],
                fontWeight: statusFontWeight[data],
              }}
            >
              {data == "compliance" ? (
                <span
                  style={{
                    color: statusColor[data],
                    fontWeight: statusFontWeight[data],
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {status[data]}
                  <GlobalTooltipIcon
                    tooltipText={
                      "Note: This product is currently undergoing Compliance Verification. For any inquiries or concerns regarding compliance status, Please reach out to our support team."
                    }
                  />
                </span>
              ) : (
                status[data]
              )}{" "}
              {!!arr.expired_at && this.state.filters.type === "completed" && (
                <div className="valid">
                  <span>Valid till</span>
                  {moment(arr.expired_at).format("DD MMM, YYYY")}
                </div>
              )}
              {arr.reject_reason_partner ? (
                <div className="reject-reason">
                  <Button
                    className="btn"
                    onClick={() =>
                      this.setState({
                        viewRejectReason: {
                          visible: true,
                          reason: arr.reject_reason_partner,
                        },
                      })
                    }
                  >
                    {" "}
                    <span className="icon-container">
                      <EyeIcon />
                    </span>{" "}
                    <span className="text">View Reason</span>
                  </Button>
                </div>
              ) : null}
            </p>
          );
        },
      },
      {
        title: "Product Details",
        dataIndex: "pd",
        width: 240,
        render: (e, d) => {
          return (
            <div
              className={
                d.files.length
                  ? "table-img-details link-modal"
                  : "table-img-details"
              }
            >
              <img
                src={d.images[0]["url"]}
                alt=""
                onClick={() => {
                  if (d.files.length) {
                    this.setState({
                      showMediaModalUrl: d.files[0],
                      showMediaModal: true,
                    });
                  }
                }}
              />
              <div className="text-wraper">
                {!!d.vfprodid ? (
                  <p>
                    {d.name.length > 20 ? (
                      <Tooltip title={d.name}>
                        {d.name.slice(0, 20) + "..."}
                      </Tooltip>
                    ) : (
                      d.name
                    )}
                  </p>
                ) : (
                  <a
                    href={d.product_url}
                    target="_blank"
                    className="name-link underline-animation"
                  >
                    {d.name.length > 22 ? (
                      <Tooltip title={d.name}>
                        {d.name.slice(0, 22) + "..."}
                      </Tooltip>
                    ) : (
                      d.name
                    )}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                    >
                      <path
                        id="open_in_new_FILL0_wght400_GRAD0_opsz48"
                        d="M121.167-826a1.119,1.119,0,0,1-.817-.35,1.119,1.119,0,0,1-.35-.817v-11.667a1.12,1.12,0,0,1,.35-.817,1.119,1.119,0,0,1,.817-.35h4.425v1.167h-4.425v11.667h11.667v-4.425H134v4.425a1.119,1.119,0,0,1-.35.817,1.119,1.119,0,0,1-.817.35Zm3.928-4.258-.817-.836,7.739-7.739h-4.258V-840H134v6.242h-1.167V-838Z"
                        transform="translate(-120 840)"
                        fill="#1268fb"
                      />
                    </svg>
                  </a>
                )}
                {!!d.mapped_vfpd ? (
                  <a
                    target="_blank"
                    // href={`../products2/${d.mapped_vfpd.vfprodid}`}
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_NEW_APP}/productDetails?${d.mapped_vfpd.vfprodid}`,
                        "_blank"
                      )
                    }
                    className="vf underline-animation"
                  >
                    <span /> {d.mapped_vfpd.vfprodid}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                    >
                      <path
                        id="open_in_new_FILL0_wght400_GRAD0_opsz48"
                        d="M121.167-826a1.119,1.119,0,0,1-.817-.35,1.119,1.119,0,0,1-.35-.817v-11.667a1.12,1.12,0,0,1,.35-.817,1.119,1.119,0,0,1,.817-.35h4.425v1.167h-4.425v11.667h11.667v-4.425H134v4.425a1.119,1.119,0,0,1-.35.817,1.119,1.119,0,0,1-.817.35Zm3.928-4.258-.817-.836,7.739-7.739h-4.258V-840H134v6.242h-1.167V-838Z"
                        transform="translate(-120 840)"
                        fill="#1268fb"
                      />
                    </svg>
                  </a>
                ) : null}
                {!!d.vfprodid ? (
                  <a
                    // target="_blank"
                    // href={`../products2/${d.vfprodid}`}
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_NEW_APP}/productDetails?${d.vfprodid}`,
                        "_blank"
                      )
                    }
                    className="vf underline-animation"
                  >
                    <span /> {d.vfprodid}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                    >
                      <path
                        id="open_in_new_FILL0_wght400_GRAD0_opsz48"
                        d="M121.167-826a1.119,1.119,0,0,1-.817-.35,1.119,1.119,0,0,1-.35-.817v-11.667a1.12,1.12,0,0,1,.35-.817,1.119,1.119,0,0,1,.817-.35h4.425v1.167h-4.425v11.667h11.667v-4.425H134v4.425a1.119,1.119,0,0,1-.35.817,1.119,1.119,0,0,1-.817.35Zm3.928-4.258-.817-.836,7.739-7.739h-4.258V-840H134v6.242h-1.167V-838Z"
                        transform="translate(-120 840)"
                        fill="#1268fb"
                      />
                    </svg>
                  </a>
                ) : null}
                {!!d.quotes.length && (
                  <div style={{ paddingTop: "12px" }}>
                    <Button
                      onClick={() => {
                        this.setState({
                          showQuoteMOdal: true,
                          moq: !!d.quotes ? d.quotes : [],
                          request_vfid: d.request_vfid,
                          currentRow: d,
                        });
                      }}
                      style={{
                        color: "#212121 !important",
                      }}
                      className="clickable view-btn-rfq "
                    >
                      View Quotation
                    </Button>
                  </div>
                )}
              </div>
            </div>
          );
        },
      },

      {
        title: "Requested Qty",
        dataIndex: "variants",
        align: "center",
        width: 190,
        render: (e, obj) => {
          if (obj.rfq_type === "external") {
            return (
              <div style={{ textAlign: "center" }} className="external-case">
                <div style={{ marginBottom: "5px" }}>
                  <span>Total Qty :</span> <span>{obj.qty}</span>
                </div>
                {!!obj.mapped_vfpd ? (
                  <div>
                    <Button
                      onClick={() => {
                        this.setState({
                          variantModal: {
                            visible: true,
                            variantData: obj.mapped_vfpd.variants,
                            name: obj.mapped_vfpd.name,
                            qty: obj.qty,
                            type: "external",
                          },
                        });
                      }}
                      style={{
                        color: "#212121 !important",
                      }}
                      className="clickable rtrty"
                    >
                      View Variants
                    </Button>
                  </div>
                ) : this.state.filters.type === "open" ? (
                  <div></div>
                ) : // <div className="progress-bar"> ⏱ Processing Variants</div>
                null}
              </div>
            );
          }

          return (
            <span>
              <div style={{ marginBottom: "5px" }}>
                <span>Total Qty :</span> <span>{obj.qty}</span>
              </div>
              <div>
                <Button
                  onClick={() => {
                    this.setState({
                      variantModal: {
                        visible: true,
                        variantData: e,
                        name: obj.name,
                        qty: obj.qty,
                        type: "internal",
                      },
                    });
                  }}
                  style={{
                    color: "#212121 !important",
                  }}
                  className="clickable rtrty"
                >
                  View Variants
                </Button>
              </div>
            </span>
          );
        },
      },

      // {
      //   title: "Exp. Landing Timeline",
      //   dataIndex: "expected_landing_date",
      //   align: "center",
      //   width: 240,
      //   render: (e) => <p>{e?e:"N/A"}</p>,
      // },
      {
        title: "Exp. Product Price",
        dataIndex: "expected_landing_product_cost",
        align: "center",
        width: 165,
        render: (e) => (
          <p>{e > 0 ? `₹ ${Number(e).toLocaleString()}` : "N/A"}</p>
        ),
      },
      {
        title: "Private Label",
        dataIndex: "private_label",
        width: 250,
        render: (e, obj) => {
          return (
            <div>
              <div style={{ textTransform: "capitalize" }}>
                <span style={{ fontWeight: 400, color: "#898989" }}>
                  Required :{" "}
                </span>
                {e === "yes" ? (
                  <span className="blue-tick-container">
                    <RightIcon />
                  </span>
                ) : (
                  <span className="red-cross-container">
                    <RedCross />
                  </span>
                )}
                {e}
              </div>
              {obj.private_label_remarks ? (
                <div>
                  {" "}
                  <span style={{ fontWeight: 400, color: "#898989" }}>
                    Additional Information :
                  </span>{" "}
                  {obj.private_label_remarks}
                </div>
              ) : null}
            </div>
          );
        },
      },
      {
        title: "Action",
        width: this.state.filters.type === "completed" ? 180 : 180,
        align: "center",
        fixed: "right",
        render: (a, arr, index) => {
          return (
            <div className="action-btn1">
              {this.state.filters.type === "completed" &&
              arr.status !== "cancelled" ? (
                <>
                  {!!arr.vfprodid && (
                    <a
                      // target="_blank"
                      // href={`../products2/${arr.vfprodid}`}
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_NEW_APP}/productDetails?${arr.vfprodid}`,
                          "_blank"
                        )
                      }
                      className="clickable"
                    >
                      Raise Sourcing Request
                    </a>
                  )}
                  {!!arr.mapped_vfpd && (
                    <a
                      // target="_blank"
                      // href={`../products2/${arr.mapped_vfpd.vfprodid}`}

                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_NEW_APP}/productDetails?${arr.mapped_vfpd.vfprodid}`,
                          "_blank"
                        )
                      }
                      className="clickable"
                    >
                      Raise Sourcing Request
                    </a>
                  )}
                  {arr.reopen_available && (
                    <Button
                      className="clickable re-open-btn"
                      onClick={() => {
                        this.setState({
                          reopenModal: {
                            visible: true,
                            id: arr._id["$oid"],
                            index: index,
                          },
                        });
                      }}
                    >
                      {" "}
                      <span className="icon-container">
                        <ReopenIcon />
                      </span>{" "}
                      <span className="text">Re-Open</span>
                    </Button>
                  )}
                </>
              ) : null // <Dropdown
              //   className="clickable"
              //   overlay={
              //     <Menu
              //       className="ali-link-menu"
              //       onClick={(e) => {
              //         if (e.key === "viewQuotation") {
              //           this.setState({
              //             showQuoteMOdal: true,
              //             moq: !!arr.quotes ? arr.quotes : [],
              //             request_vfid: arr.request_vfid,
              //             currentRow: arr,
              //           });
              //         }
              //         if (e.key === "reopen") {
              //           this.setState({
              //             reopenModal: {
              //               visible: true,
              //               id: arr._id["$oid"],
              //               index: index,
              //             },
              //           });
              //         }
              //       }}
              //     >
              //       {!!arr.vfprodid && (
              //         <Menu.Item key="approved">
              //           {" "}
              //           <a
              //             target="_blank"
              //             href={`../products2/${arr.vfprodid}`}
              //           >
              //             Raise Sourcing Request
              //           </a>
              //         </Menu.Item>
              //       )}
              //       {!!arr.mapped_vfpd_on && (
              //         <Menu.Item key="approved">
              //           {" "}
              //           <a
              //             target="_blank"
              //             href={`../products2/${arr.mapped_vfpd.vfprodid}`}
              //           >
              //             Raise Sourcing Request
              //           </a>
              //         </Menu.Item>
              //       )}
              //       {!!arr.quotes.length && (
              //         <Menu.Item key="viewQuotation">
              //           {" "}
              //           View Quotation
              //         </Menu.Item>
              //       )}
              //       {arr.reopen_available && (
              //         <Menu.Item key="reopen"> Re-Open</Menu.Item>
              //       )}
              //     </Menu>
              //   }
              //   trigger={["click"]}
              // >
              //   <Button
              //     type="primary"
              //     className="takeAction"
              //     style={{
              //       maxHeight: 48,
              //       color: "#fff",
              //     }}
              //   >
              //     Take Actions{" "}
              //     <Icon type="down" style={{ color: "#fff !important" }} />
              //   </Button>
              // </Dropdown>
              }
              {/* {this.state.filters.type === "completed" && !arr.files.length ? (
                <div>N/A</div>
              ) : null} */}
              {this.state.filters.type === "completed" &&
              arr.status === "cancelled" &&
              arr.reopen ? (
                <>
                  {" "}
                  {!!arr.vfprodid && (
                    <a
                      // target="_blank"
                      // href={`../products2/${arr.vfprodid}`}

                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_NEW_APP}/productDetails?${arr.vfprodid}`,
                          "_blank"
                        )
                      }
                      className="clickable"
                    >
                      Raise Sourcing Request
                    </a>
                  )}
                  {!!arr.mapped_vfpd && (
                    <a
                      // target="_blank"
                      // href={`../products2/${arr.mapped_vfpd.vfprodid}`}

                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_NEW_APP}/productDetails?${arr.mapped_vfpd.vfprodid}`,
                          "_blank"
                        )
                      }
                      className="clickable"
                    >
                      Raise Sourcing Request
                    </a>
                  )}
                </>
              ) : null}
              {this.state.filters.type === "open" &&
              arr.status !== "in_processing" &&
              arr.status !== "compliance" ? (
                <Dropdown
                  className="clickable"
                  overlay={
                    <Menu
                      className="ali-link-menu"
                      onClick={(e) => {
                        this.setState({
                          cancelModal: {
                            visible: true,
                            id: arr._id["$oid"],
                            index: index,
                          },
                        });
                      }}
                    >
                      <Menu.Item key="close"> Cancel Quotation </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <Button
                    type="primary"
                    className="takeAction"
                    style={{
                      maxHeight: 48,
                      color: "#fff",
                    }}
                  >
                    Take Actions{" "}
                    <Icon type="down" style={{ color: "#fff !important" }} />
                  </Button>
                </Dropdown>
              ) : null}
              {/* {this.state.filters.type === "rejected" && (
                <>
                  {" "}
                  {!!arr.vfprodid && (
                    <a
                      target="_blank"
                      href={`../products2/${arr.vfprodid}`}
                      className="clickable"
                    >
                      Raise Sourcing Request
                    </a>
                  )}
                  {!!arr.mapped_vfpd_on && (
                    <a
                      target="_blank"
                      href={`../products2/${arr.mapped_vfpd.vfprodid}`}
                      className="clickable"
                    >
                      Raise Sourcing Request
                    </a>
                  )}
                </>
              )} */}
              {!!arr.files.length && (
                // <a
                //   href="#"
                //   onClick={() =>
                //     this.setState({
                //       showMediaModalUrl: arr.files[0],
                //       showMediaModal: true,
                //     })
                //   }
                //   style={{ color: "#d21b5c" }}
                //   className="df underline-animatio"
                // >
                //   <span className="image-continer">
                //     {" "}
                //     <Imageplaceholder />{" "}
                //   </span>{" "}
                //   My Media
                // </a>
                <Button
                  onClick={() =>
                    this.setState({
                      showMediaModalUrl: arr.files[0],
                      showMediaModal: true,
                    })
                  }
                  style={{ color: "#d21b5c" }}
                  // className="df underline-animatio"
                  className="media-btn"
                >
                  <Imageplaceholder />
                  <span>My Media</span>
                </Button>
              )}{" "}
              {this.state.filters.type === "open" &&
              !arr.files.length &&
              (arr.status === "in_processing" ||
                arr.status === "compliance") ? (
                <div>
                  <Tooltip title="No action can be taken">
                    <Button
                      // type="primary"
                      className="takeAction"
                      disabled
                      style={{
                        maxHeight: 48,
                        color: "#fff",
                      }}
                    >
                      Take Actions{" "}
                      {/* <Icon type="down" style={{ color: "#fff !important" }} /> */}
                    </Button>
                  </Tooltip>
                </div>
              ) : null}
              {this.state.filters.type === "rejected" && !arr.files.length ? (
                <div>
                  {" "}
                  <Tooltip title="No action can be taken">
                    <Button
                      // type="primary"
                      className="takeAction"
                      disabled
                      style={{
                        maxHeight: 48,
                        color: "#fff",
                      }}
                    >
                      Take Actions{" "}
                      {/* <Icon type="info" theme="outlined" style={{ color: "#fff !important" }} /> */}
                    </Button>
                  </Tooltip>
                </div>
              ) : null}
              {this.state.filters.type === "completed" &&
              arr.status === "cancelled" &&
              !arr.reopen &&
              !arr.files.length ? (
                <div>
                  {" "}
                  <Tooltip title="No action can be taken">
                    <Button
                      // type="primary"
                      className="takeAction"
                      disabled
                      style={{
                        maxHeight: 48,
                        color: "#fff",
                      }}
                    >
                      Take Actions{" "}
                      {/* <Icon type="down" style={{ color: "#fff !important" }} /> */}
                    </Button>
                  </Tooltip>
                </div>
              ) : null}
            </div>
          );
        },
      },
    ];

    const filters = this.state.filters;

    const PageWrapper = (children, showSearch = true) => {
      return (
        <div className="open-rfq-wraper">
          {!!this.state.showMediaModal && (
            <Modal
              className="media-modal"
              title="Media"
              width={800}
              footer={null}
              destroyOnClose={true}
              {...this.props}
              visible={this.state.showMediaModal}
              onCancel={() =>
                this.setState({ showMediaModal: false, showMediaModalUrl: "" })
              }
            >
              {this.imgHandler(this.state.showMediaModalUrl)}{" "}
            </Modal>
          )}{" "}
          <RfqNav
            activeTab={this.state.filters.type}
            showSearch={showSearch}
            loading={this.state.loading}
            count={this.state.count}
            filters={this.state.filters}
            changeHandler={(e) => this.changeHandler(e)}
            changeFilter={(e) => this.changeFilter(e)}
            data={this.state.filters}
            loadData={() => this.loadData()}
            activeTabHandler={(e) => this.activeTabHandler(e)}
            rfqHandler={() => this.setState({ showRfqModal: true })}
            rfqLeft={this.state.rfqLeft}
            rfqAvailability={this.state.rfqAvailability}
          />
          {this.state.showRfqModal ? (
            <RaiseRFQModal
              {...this.props}
              loadData={() => this.loadData()}
              visible={this.state.showRfqModal}
              activeTabHandler={(activeTab) => this.activeTabHandler(activeTab)}
              onCancel={() =>
                this.setState({ showRfqModal: false }, () => this.loadData())
              }
            />
          ) : null}
          {this.state.showQuoteMOdal ? (
            <QuoteModal
              {...this.props}
              loadData={() => this.loadData()}
              currentRow={this.state.currentRow}
              request_vfid={this.state.request_vfid}
              moq={this.state.moq}
              onCancel={() => this.setState({ showQuoteMOdal: false })}
              visible={this.state.showQuoteMOdal}
            />
          ) : null}
          {children}
          {this.state.variantModal.visible ? (
            <VariantModal
              onCancel={() => {
                this.handleVariantDetailClose();
              }}
              modalData={this.state.variantModal}
            />
          ) : null}
          {this.state.cancelModal.visible ? (
            <CancelModal
              data={this.state.cancelModal}
              onCancel={() => {
                this.setState({
                  cancelModal: {
                    visible: false,
                    id: "",
                    index: null,
                  },
                });
              }}
              rowHandler={(index) => {
                let arr = [...this.state.rfqData];
                arr.splice(index, 1);
                this.setState({
                  rfqData: arr,
                });
              }}
            />
          ) : null}
          {this.state.reopenModal.visible ? (
            <ReopenModal
              data={this.state.reopenModal}
              onCancel={() => {
                this.setState({
                  reopenModal: {
                    visible: false,
                    id: "",
                    index: null,
                  },
                });
              }}
              rowHandler={(index) => {
                let arr = [...this.state.rfqData];

                arr.splice(index, 1);
                this.setState({
                  rfqData: arr,
                });
              }}
            />
          ) : null}
          {this.state.viewRejectReason.visible ? (
            <ViewReasonModal
              data={this.state.viewRejectReason}
              onCancel={() =>
                this.setState({
                  viewRejectReason: {
                    visible: false,
                    reason: "",
                  },
                })
              }
            />
          ) : null}
        </div>
      );
    };

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <div style={{ marginTop: "52px" }} id="fade-in" className="er-wr">
          <ErrorCard
            errorMsg={this.state.errorMsg}
            errorCode={this.state.errorCode}
          />
        </div>,
        false
      );
    }

    if (
      (filters.endDate.length ||
        filters.startDate.length ||
        filters.search.length ||
        filters.type !== "open" ||
        filters.status.length) &&
      this.state.rfqData &&
      this.state.rfqData.length === 0 &&
      !this.state.loading
    ) {
      return PageWrapper(
        <div style={{ marginTop: "52px" }} id="fade-in">
          <NotFoundCard
            mainHeading={"Oops! No Data Found."}
            secondHeading={<p>Looks like there are no RFQ's in this page.</p>}
          />
        </div>
      );
    }

    if (
      !this.state.loading &&
      this.state.rfqData &&
      this.state.rfqData.length === 0
    ) {
      return PageWrapper(this.noRfqBanner(), false);
    }

    return PageWrapper(
      <div id="fade-in">
        <Table
          scroll={{ x: 800 }}
          loading={this.state.loading}
          className="rfq-table"
          dataSource={this.state.rfqData}
          pagination={this.state.pagination}
          columns={columns}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }

  noRfqBanner() {
    return (
      <div id="fade-in" className="no-rfq">
        <div className="left">
          <h1>Request A Quote!</h1>

          <h2>
            Found a Product on vFulfill that grabbed your attention? Or, is
            there a desired product that you couldn’t find on vFulfill?
          </h2>

          <h3>
            You can Request For Quote of any Product by providing its relevant
            details and we will bring its most accurate, up-to-date information
            to you.
          </h3>
          {this.state.rfqLeft > 0 ? (
            <Button
              onClick={() => this.setState({ showRfqModal: true })}
              type="primary"
            >
              Request Quotation
            </Button>
          ) : (
            <Tooltip
              open={true}
              title={
                <div>
                  Upgrade subscription for more quotation requests.
                  <div style={{ marginTop: "12px" }}>
                    <a
                      href="../../../profile/?plan=upgradesubscriptionplan"
                      style={{
                        border: "1px solid #0068FF",
                        padding: "5px",
                        borderRadius: "4px",
                        backgroundColor: "#0068FF",
                        color: "#fff",
                        fontWeight: "500",
                      }}
                      target="_blank"
                    >
                      {" "}
                      Upgrade Plan Now
                    </a>
                  </div>
                </div>
              }
              placement="topLeft"
            >
              <div className="roq-btb-container">
                <Button
                  className="nav-rfq-btn"
                  type="primary"
                  onClick={() => this.setState({ showRfqModal: true })}
                  disabled={true}
                >
                  Request Quotation
                </Button>
                <div className="rfq-left-dis">
                  {" "}
                  <span className="labe">Credits left :</span>{" "}
                  <span className="valu">
                    {this.state.rfqLeft} / {this.state.rfqAvailability}
                  </span>
                </div>
              </div>
            </Tooltip>
          )}
        </div>

        <div className="right">
          <BlankIllustration />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
    count: state.auth.count,
  };
};
export default connect(mapStateToProps)(Rfq);
